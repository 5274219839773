import withStyles from 'react-jss';
import {
  CssType,
  ThemeType,
} from '@theming/jssTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, message, Button, Table } from 'antd';
import ExcelUpload from '@components/common/ExcelUpload';
import { SizeMe } from 'react-sizeme';
import { downloadFileData, promiseAllWithConcurrency } from '@utils/utils';
import { downloadSampleCSV } from '@api/retailDashboard';
import { withRouter, Redirect } from 'react-router';
import {
  uploadMultipleCN, uploadMultipleCnsWithPieces,
  uploadMultipleCNsForCNV, updateMultipleCnsWithPieces,
} from '@api/genericConsignmentView';
import {
  errorColumnsForPieces, defaultErrorColumns, errorColumnsForCNV,
  cnvViaExcelMapping,
  sampleCSVForCNV, sampleCSVForExpressBulkUpload,
  sampleCSVWithPieces, defaultSampleCSV, bulkActionConfig, bulkActionEnum, arrayColumnsList
} from '@components/pages/retail/dashboard/BulkUploadConsignmentConfig';
import { getConsignmentUploadRequestRoute } from '@routing/utils';
import { withTranslation } from 'react-i18next';
import lodash from 'lodash';
import { GenericBulkUploadService } from 'src/components/common/GenericBulkUploadService';

const styles = (theme: ThemeType): CssType => ({
  tableMain: {
    '& .ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorters': {
      paddingRight: '34px',
    },
    '& .ant-table-thead > tr > th .ant-table-column-sorters': {
      paddingRight: '8px',
    },
  },
  uploadDirection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '16px',
  },
  customHeaderLayout: {
    color: theme.errorColor,
    fontSize: '14px',
    marginLeft: '16px',
  },
  row: {
    display: 'flex',
  },
  uploadCol: {
    fontSize: '15px',
    padding: '16px',
    fontWeight: 'bold',
  },
  tableLayout: {
    marginLeft: '16px',
    marginRight: '16px',
    overflow: 'auto',
  },
  footerLayout: {
    margin: '16px',
    paddingBottom: '15px',
  },
  downloadButton: {
    float: 'left',
    color: `${theme.primaryColor} !important`,
    background: 'transparent !important',
    boxShadow: 'none !important',
    border: 'none !important',
    fontSize: '14px',
  },
  customUploadButton :{
    float:'right',
    marginLeft:10,
    marginTop:25,
  },
  customCancelButton : {
    float:'right',
    marginTop:25,
  },
});
class MultiShipmentUpload extends React.PureComponent<any, any>{
  state: any = {
    files: [],
    isFileTypeCorrect: true,
    failures: [],
    toBeUploaded: [],
    isProcessed: false,
    confirmLoading: false,
    showFooter: false,
    dataToUpload: [],
    async: false,
  };

  createOrUpdateData = async (apiCallFn, data, updateConsignment) => {
    const batchRequests = !updateConsignment && this.props.enableAsyncConsignmentSoftdataUpload;
    if (!batchRequests) {
      return await apiCallFn(data);
    }
    const consignments = data?.consignments || [];
    const pieces = data?.pieces || [];
    const items = data?.items || [];
    const requestData = {};
    Object.keys(data).forEach(key => {
      if (!['consignments', 'pieces', 'items'].includes(key)) {
        requestData[key] = data[key];
      }
    });
    const batchSize = 3000;
    const batchedData = lodash.chunk(consignments, batchSize);
    const promises = [];
    const uniqueIdPresent = pieces.every(row => {
      return row.unique_id;
    });

    if (pieces?.length && !uniqueIdPresent) {
      return {
        isSuccess: false,
        errorMessage: 'Piece Unique Id is required for each piece',
      };
    }

    if (items?.length) {
      const uniqueIdPresentItems = items.every(row => {
        return row.piece_unique_id;
      });

      if (!uniqueIdPresentItems) {
        return {
          isSuccess: false,
          errorMessage: 'Piece Unique Id is required for each item',
        };
      }

      const uniqueIdPresentInPieces = pieces.every(row => {
          return row.piece_unique_id;
      });

      if (!uniqueIdPresentInPieces) {
        return {
          isSuccess: false,
          errorMessage: 'Piece Unique Id is required for each piece',
        };
      }
    }

    const piecesHashMap = lodash.groupBy(pieces, 'unique_id');
    const itemsHashMap = lodash.groupBy(items, 'piece_unique_id');

    for (let index = 0; index < batchedData.length; index++) {
      const batch = batchedData[index];
      const piecesData = batch.map((cn: any) => piecesHashMap[cn.unique_id] || []).flat();
      const itemsData = pieces.map((piece: any) => itemsHashMap[piece.piece_unique_id] || []).flat();
      const requestDataToPush: any = {
        ...requestData,
        consignments: batch,
        pieces: piecesData,
        items: itemsData,
      };

      if (batchedData.length > 1) {
        requestDataToPush.batchId = `PART${index+1}`;
      }
      promises.push(async () => {
        return await apiCallFn(requestDataToPush);
      });
    }

    const result = await promiseAllWithConcurrency(promises, 3);

    const allSuccess = result.every(res => res.isSuccess);
    const someSuccess = result.some(res => res.isSuccess);

    if (allSuccess) {
      return {
        isSuccess: true,
        data: {
          failures: [],
          request_type: 'async',
        },
      };
    } else if (someSuccess) {
      message.error('Some of the consignments failed to upload');
      return {
        isSuccess: true,
        data: {
          failures: [],
          request_type: 'async',
        },
      };
    } else {
      return {
        isSuccess: false,
        errorMessage: 'All consignments failed to upload',
      };
    }
  };

  uploadExcel = async (data) => {
    const { updateConsignment, isPiecesUpdate, t, enableAsyncConsignmentSoftdataUpload } = this.props;
    const consignmentLimit = enableAsyncConsignmentSoftdataUpload ? 20000 : 3000;
    const errorMessageKey = enableAsyncConsignmentSoftdataUpload ?
      'maximum_20000_consignments_can_be_uploaded' : 'maximum_3000_consignments_can_be_uploaded';
    if (data?.consignments?.length > consignmentLimit) {
      this.setState({
        confirmLoading: false,
      });
      this.props.handleModalClose(true);
      return {
        isSuccess: false,
        errorMessage: t(errorMessageKey),
      };
    }

    let apiCallFn;

    if (updateConsignment) {
      data.is_pieces_update = isPiecesUpdate ? true : false;
      apiCallFn = updateMultipleCnsWithPieces;
    } else {
      apiCallFn = uploadMultipleCnsWithPieces;
    }

    return await this.createOrUpdateData(apiCallFn, data, updateConsignment);
  }

  handleSubmit = async () => {
    const { showLogisticsExpressOptions, cnVerificationViaExcel, crmVerification, t } = this.props;

    const { dataToUpload } = this.state;
    const data = dataToUpload;
    if (data.length === 0) {
      message.warning(t('no_consignments_to_upload'));
      return;
    }
    this.setState({
      confirmLoading: true,
    });

  !showLogisticsExpressOptions && data?.map((item) => {
      const filteredData = (item?.constraint_tags && typeof item.constraint_tags === 'string') ?
        item.constraint_tags.split(',') : [];
      item.constraint_tags = filteredData?.map((ele) => ele.trim());
    });

    const body = {
      consignments: data,
    };
    let response: any = {};
    if (cnVerificationViaExcel) {
      try {
        GenericBulkUploadService.uploadData({
          heading: 'Update Consignment',
          data,
          apiCallFn: uploadMultipleCNsForCNV,
          getRequestBody: (arrData) => {
            return { consignments: arrData, source: crmVerification ? 'crm' : 'ops' };
          },
          failureArrayKeysToShow: ['reference_number', 'message', 'reason'],
        });
        this.handleModalClose();
        return;
      } catch (e) {
        message.error(e.toString());
      }

    } else if (showLogisticsExpressOptions) {
      response = await this.uploadExcel(data);
    } else {
      response = await uploadMultipleCN(body);
    }
    if (response.isSuccess) {
      if (response.data && response.data.failures && response.data.failures.length) {
        this.setState({
          failures: response.data.failures,
          confirmLoading: false,
          isProcessed: true,
          showFooter: false,
          async: response.data?.request_type === 'async' ? true : false,
        });
      } else {
        this.setState({
          failures: [],
          confirmLoading: false,
          isProcessed: true,
          showFooter: false,
          async: response.data?.request_type === 'async' ? true : false,
        });
        if(response.data?.request_type !== 'async') {
          message.success(t('consignments_uploaded_successfully'));
          this.props.handleModalClose(true);
        }
      }
    } else {
      this.setState({
        confirmLoading: false,
        isProcessed: true,
        showFooter: false,
      });
      message.error(response.errorMessage);
      this.props.handleModalClose();
    }
  };

  handleSampleFileDownload = async () => {
    const { updateConsignment, isPiecesUpdate, cnVerificationViaExcel, t, type } = this.props;

    let action = 'consignment';
    if (cnVerificationViaExcel) {
      action = bulkActionEnum.CNV;
    }
    if (updateConsignment) {
      action = 'update_consignment';
    }
    if (isPiecesUpdate) {
      action = 'update_consignment_pieces';
    }
    if (type) {
      action = type;
    }

    const actionConfig = bulkActionConfig[action];

    const handlerFn: any = actionConfig.handler;
    if (!handlerFn) {
      message.error(t('not_able_to_generate_sample_csv'));
      return;
    }

    const fileName = actionConfig.fileName || 'sample';
    const extension = actionConfig.extension || '.csv';
    const response = await handlerFn(actionConfig.params);
    if (response.isSuccess) {
      downloadFileData(response.data, fileName, extension);
    } else {
      message.error(response.errorMessage);
    }
  };

  preProcessPrasedData = (dataListArray) => {
    for(const data of dataListArray) {
      for(const field of arrayColumnsList) {
        if(data[field] && typeof data[field] === 'string') {
          data[field] = data[field].replace(/[{}]/g, '').split(',').map(item => item.trim());
        }
      }
    }
    return;
  }

  handleModalClose = () => {
    // this.props.resetBulkUpload();
    this.props.handleModalClose(false);
  };
  handleParsedData = (data) => {
    const { t } = this.props;
    if (data.length > 0) {
      this.setState({
        dataToUpload: data,
        showFooter: true,
      });
    } else {
      message.warning(t('no_consignments_to_upload'));
    }
  };
  handleParseMutipleSheets = (data) => {
    const { t, updateConsignment } = this.props;
    const consignments = data?.Consignments || [];
    const pieces = data?.Pieces || [];
    this.preProcessPrasedData(consignments);
    this.preProcessPrasedData(pieces);
    if (!consignments.length && !updateConsignment) {
      message.error(t('no_consignment_found'));
      return;
    }
    const cnHash = new Map(consignments.map((e, index) => [e.reference_number, index]));
    if (cnHash.size !== consignments.length) {
      message.error(t('duplicate_consignments_found'));
      return;
    }
    for (const piece of pieces) {
      if (cnHash.has(piece.reference_number)) {
        const i: any = cnHash.get(piece.reference_number);
        if (!consignments[i].pieces) {

          consignments[i].pieces = [piece];
        } else {
          consignments[i].pieces.push(piece);
        }
      }
    }
    this.setState({
      dataToUpload: consignments,
      showFooter: true,
    });
  };

  handleParseMutipleSheetsExpress = (data) => {
    const { t, updateConsignment } = this.props;
    const consignments = data?.Consignments || [];
    const pieces = data?.Pieces || [];
    const items = data?.Items || [];
    this.preProcessPrasedData(consignments);
    this.preProcessPrasedData(pieces);

    if (!consignments.length && !updateConsignment) {
      message.error(t('no_consignment_found'));
      return;
    }
    let consignmentAndPieceObject: any = {};

    const { enablePieceItemsDetail } = this.props;
    if (enablePieceItemsDetail) {
      consignmentAndPieceObject = {
        consignments,
        pieces,
        items,
      };
    } else {
      consignmentAndPieceObject = {
        consignments,
        pieces,
      };
    }

    this.setState({
      dataToUpload: consignmentAndPieceObject,
      showFooter: true,
    });
  };
  render() {
    const { classes, theme, isVisible, withPieces, showLogisticsExpressOptions,crmVerification,
      cnVerificationViaExcel, showQcStatus, showMarkRtoOptions, enableHeaderMapping,
      enablePieceItemsDetail, updateConsignment, t } = this.props;
    let columns: any = [];
    if (cnVerificationViaExcel) {
      columns = errorColumnsForCNV;
    } else if (showLogisticsExpressOptions) {
      columns = errorColumnsForPieces;
    } else {
      columns = defaultErrorColumns;
    }

    if(this.state.async) {
      return <Redirect to={getConsignmentUploadRequestRoute()} />;
    }
    const uploadLayout = <div>
      <ExcelUpload
        mapping={cnVerificationViaExcel ? cnvViaExcelMapping :
          null}
        enableHeaderMapping = {enableHeaderMapping}
        firstRowAsHeader={!cnVerificationViaExcel}
        headerText={t('upload_csv_file_multiple_shipments')}
        onDataParsed={this.handleParsedData}
        sampleApi={this.handleSampleFileDownload}
      />
    </div>;
    const multipleSheetUploadLayout = <div>
      <ExcelUpload
      multipleSheets={this.props.withPieces || this.props.showLogisticsExpressOptions}
        headerText={t('upload_csv_file_multiple_shipments')}
        enableHeaderMapping = {enableHeaderMapping}
        onDataParsed={showLogisticsExpressOptions ?
          this.handleParseMutipleSheetsExpress : this.handleParseMutipleSheets}
        sampleApi={this.handleSampleFileDownload}
      />
    </div>;
    const { failures } = this.state;
    const shipmentErrorLayout = (
      failures.length ?
        <div>
          <div className={classes.row}>
            <div className={classes.uploadCol} style={{ color: theme.errorColor }}>
              <ExclamationCircleFilled />
              <span style={{ paddingLeft: '15px' }}>
                {failures.length} errors were found</span>
            </div>
          </div>
          <div className={classes.tableLayout}>
            <SizeMe monitorHeight>
              {({ size }) => {
                return (<Table
                  bordered
                  pagination={{
                    simple: true,
                    hideOnSinglePage: true,
                    position: ['topRight'],
                    pageSize: 7,
                  }}
                  columns={columns}
                  dataSource={failures}
                  className={classes.tableMain}
                  locale={{ emptyText: 'No errors found' }}
                  size="middle"
                ></Table>);
              }}
            </SizeMe>
          </div>
        </div> :
        <div>{t('consignments_uploaded_successfully')}</div>
    );
    if(crmVerification)
    {
      return (<div>
        {!this.state.isProcessed ?
          ((withPieces && !cnVerificationViaExcel) ||
            (showLogisticsExpressOptions && !cnVerificationViaExcel)
            ? multipleSheetUploadLayout : uploadLayout) :
          shipmentErrorLayout
        }
        <footer className={classes.footerLayout}>
          <Button className={classes.customUploadButton} type="primary" disabled={!this.state.showFooter}
            onClick={this.handleSubmit} loading={this.state.confirmLoading}>Upload</Button>
          <Button className={classes.customCancelButton} disabled={this.state.confirmLoading}
            onClick={() => this.props.handleModalClose(false)}>Cancel</Button>
        </footer>
        </div>
      );
    }
    return (<Modal
      bodyStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
      maskClosable={false}
      centered
      onCancel={this.handleModalClose}
      visible={isVisible}
      footer={this.state.showFooter ?
        [
          <Button disabled={this.state.confirmLoading}
            onClick={() => this.props.handleModalClose(false)}>No</Button>,
          <Button type="primary"
            onClick={this.handleSubmit} loading={this.state.confirmLoading}>Yes</Button>]
        : null
      }
      zIndex={1}
      destroyOnClose={true}
      width={330}
      title={t('Bulk Upload')}
    >{
        !this.state.isProcessed ?
          ((withPieces && !cnVerificationViaExcel)
          || (showLogisticsExpressOptions && !cnVerificationViaExcel)
            ? multipleSheetUploadLayout : uploadLayout) :
          shipmentErrorLayout
      }
    </Modal>);
  }
}
const mapStateToProps = ({ masterData }) => {
  return {
    withPieces: masterData.bulk_upload_cn_with_piece_details || false,
    showLogisticsExpressOptions: masterData.show_logistics_express_options || false,
    showQcStatus: masterData.cnv_config?.show_qc_status,
    showMarkRtoOptions: masterData?.cnv_config?.show_mark_rto_option,
    enableHeaderMapping:
      masterData.ops_dashboard_config?.parts_to_show?.enable_consignment_softdata_header_mapping
      || false,
      enablePieceItemsDetail:
      masterData.ops_dashboard_config?.parts_to_show?.enable_piece_items_detail || false,
    // TODO: remove enable_async_consignment_softdata_upload_in_parts after testing is successful on prod
    enableAsyncConsignmentSoftdataUpload:
      masterData.ops_dashboard_config?.parts_to_show?.enable_async_consignment_softdata_upload &&
      masterData.ops_dashboard_config?.parts_to_show?.enable_async_consignment_softdata_upload_in_parts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
};
const MultiShipmentUploadStyled: any =
  withTranslation('translation')(
    withStyles(styles, { injectTheme: true })(MultiShipmentUpload));

export default connect(mapStateToProps,
  mapDispatchToProps)(withRouter(MultiShipmentUploadStyled)) as React.ComponentType<any>;
